<script
    lang="ts"
    setup
>
    type Props = {
        hideLines?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        hideLines: false
    })

    const dividerClasses = computed<string>(() => {
        let classes = 'appearance-none flex-1 h-px m-0 border-none bg-[#e3e5eb]'

        if (props.hideLines) {
            classes += ' opacity-0'
        }

        return classes
    })
</script>

<template>
    <div class="whitespace-nowrap flex items-center justify-center h-[50px] w-full">
        <div :class="dividerClasses"></div>

        <div class="truncate max-w-[400px] mx-4 text-[14px] leading-[120%]">
            <slot/>
        </div>

        <div :class="dividerClasses"></div>
    </div>
</template>
